import axios from 'axios';

const location = window.location;

let url = "http://api.appandwebstudio.com.br/";

if(location.hostname == 'localhost'){
  url = 'http://localhost:8000/';
}else{
  url = 'https://api.appandwebstudio.com.br/';
}

const instance = axios.create({
  baseURL: url
});

// Interceptor para lidar com erros
instance.interceptors.response.use(
  response => response, // Retorna a resposta normalmente se não houver erro
  error => {
    if (error.response && (error.response.status === 400 || error.response.status === 401)) {
      // Redireciona para a URL de login dependendo do ambiente
      if (window.location.hostname === "localhost") {
        window.location.href = "http://localhost:3000/login";
      } else {
        window.location.href = "https://appandwebstudio.com.br/login";
      }
    }
    // Rejeita a Promise com o erro para que outros manipuladores possam usá-la, se necessário
    return Promise.reject(error);
  }
);

export default instance;