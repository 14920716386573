import React, { Suspense, useEffect, useState, useLayoutEffect } from 'react';
import { renderRoutes } from 'react-router-config';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, LinearProgress, Drawer, Toolbar } from '@material-ui/core';
import clsx from 'clsx';
import logo from '../assets/logo.png';
import ListMenu from './list-menu';

import { AppBar, Typography, Button, IconButton, Hidden } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';

const drawerWidth =  280;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: '#F7F7FC',
    minHeight: '100vh'
  },
  progress: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: 'rgb(6, 180, 184)'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    transition: '1s',
    transitionTimingFunction: 'ease-out'
  },
  drawerPaper: {
    width: drawerWidth,
    //borderRadius: '0px 15px 15px 0px',
    backgroundColor: '#FFF',
    border: 'none',
    //boxShadow: theme.shadows[3]
  },
  drawerMobile: {
    width: '80%',
    flexShrink: 0,
    transition: '1s',
    transitionTimingFunction: 'ease-out'
  },
  drawerPaperMobile: {
    width: '80%',
    //borderRadius: '0px 15px 15px 0px',
    backgroundColor: '#FFF',
    border: 'none',
    //boxShadow: theme.shadows[3]
  },
  drawerContainer: {
    overflow: 'auto'
  },
  content: {
    flexGrow: 1,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(9),
    overflowY: 'auto'
  },
  main: {
    flexGrow: 1,
    paddingBottom: theme.spacing(3),
    overflowY: 'auto'
  },
  notchedOutline: {
    borderWidth: "1px",
    borderColor: "#FFFFFF !important",
    color: '#FFFFFF'
  },
  whiteColor: {
    color: "#FFFFFF"
  },
  testeCss: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  buttonUpdateLogo: {
    width: '100%', 
    backgroundColor: '#06B4B8', 
    color: '#FFFFFF', 
    textTransform: 'inherit'
  },
  imageMenu: {
    width: '100px',
    /*display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto'*/
    paddingLeft: '36px'
  },
  imageMenuSmall: {
    width: '60%'
  },
  toolbar: {
    marginTop: '30px'
  },
  button: {
    textTransform: 'none',
    backgroundColor: '#FFDE5A',
    boxShadow: 'none',
    borderRadius: '8px',
    color: '#000',
    //float: 'right',
    paddingLeft: '20px',
    paddingRight: '20px',
    '@media (max-width: 779px)': {
      //width: '100%'
    }
  }
}));

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

const Layout = props => {

  const { route } = props;
  const classes = useStyles();

  const [locationName, setLocaitonName] = useState();

  const [width] = useWindowSize();

  useEffect(() => {

    if(window.location.pathname == '/dashboard'){
      setLocaitonName('Dashboard');
    }else if(window.location.pathname == '/financial'){
      setLocaitonName('Financeiro');
    }else if(window.location.pathname == '/projects'){
      setLocaitonName('Projetos');
    }else if(window.location.pathname == '/documents'){
      setLocaitonName('Documentos');
    }else if(window.location.pathname == '/sales'){
      setLocaitonName('Vendas');
    }

  }, [window.location.pathname])

  const [openModalNewFinancial, setOpenModalNewFinancial] = useState(false);
  const [openModalNewInvoice, setOpenModalNewInvoice] = useState(false);
  const [openModalNewProject, setOpenModalNewProject] = useState(false);
  const [openModalNewDocument, setOpenModalNewDocument] = useState(false);
  const [openModalNewBudget, setOpenModalNewBudget] = useState(false);
  const [openReport, setOpenReport] = useState(false)

  const [openDrawer, setOpenDrawer] = useState(true);
  const [openDrawerMobile, setOpenDrawerMobile] = useState(false);

  const [valueTabFinancial, setValueTabFinancial] = useState();

  return (
    <div className={classes.root}>

      <Hidden only={['md', 'lg', 'xl']}>
        <Drawer
          className={classes.drawerMobile}
          variant='temporary'
          classes={{
            paper: classes.drawerPaperMobile
          }}
          open={openDrawerMobile}
          onClose={() => {setOpenDrawerMobile(false)}}
        >
          <div className={classes.drawerContainer}>
            <div className={classes.toolbar} />
            <img alt="" src={logo} className={classes.imageMenu} />
            <ListMenu sizeMenu="full" onClick={() => {setOpenDrawerMobile(false)}} />
            <IconButton onClick={() => {setOpenDrawerMobile(false)}} style={{ position: 'absolute', top: 15, right: 20 }}>
              <CloseIcon />
            </IconButton>
          </div>
        </Drawer>
      </Hidden>

      <Hidden only={['xs', 'sm']}>
        <Drawer
          className={classes.drawer}
          variant='permanent'
          classes={{
            paper: classes.drawerPaper
          }}
          open={openDrawer}
          onClose={() => {setOpenDrawer(false)}}
        >
          <div className={classes.drawerContainer}>
            <div className={classes.toolbar} />
            <img alt="" src={logo} className={classes.imageMenu} />
            <ListMenu sizeMenu="full" onClick={() => {setOpenDrawer(false)}} />
          </div>
        </Drawer>
      </Hidden>

      <main className={clsx(classes.main)}>

        <Suspense fallback={<LinearProgress />}>

          <Hidden only={['md', 'lg', 'xl']}>

            <AppBar position="relative" style={{ backgroundColor: '#FFF', boxShadow: 'none', height: '75px', color: '#000', justifyContent: 'center', position: 'fixed', width: '100%' }}>
              <Toolbar>

                <Typography style={{ flexGrow: 1 }}>
                  {locationName}
                </Typography>
                
                <IconButton onClick={() => {setOpenDrawerMobile(true)}}>
                  <MenuIcon />
                </IconButton>

              </Toolbar>
            </AppBar>

          </Hidden>

          <Hidden only={['xs', 'sm']}>
            
            <AppBar position="relative" style={{ backgroundColor: '#FFF', boxShadow: 'none', height: '75px', color: '#000', justifyContent: 'center', position: 'fixed', width: width - drawerWidth }}>
              <Toolbar>

                <Typography style={{ flexGrow: 1 }}>
                  {locationName}
                </Typography>

                {window.location.pathname == '/financial' && (
                  <>
                    {valueTabFinancial == 0 && (
                      <>
                        <Button onClick={() => {setOpenReport(true)}} className={classes.button}>Emitir relatório</Button>&nbsp;
                        <Button onClick={() => {setOpenModalNewFinancial(true)}} className={classes.button}>Adicionar movimentação</Button>
                      </>
                    )}
                    {valueTabFinancial == 1 && (
                      <Button onClick={() => {setOpenModalNewInvoice(true)}} className={classes.button}>Criar fatura</Button>
                    )}
                  </>
                )}
                {window.location.pathname == '/projects' && (
                  <>
                    <Button onClick={() => {setOpenModalNewProject(true)}} className={classes.button}>Adicionar projeto</Button>
                  </>
                )}
                {window.location.pathname == '/documents' && (
                  <>
                    <Button onClick={() => {setOpenModalNewDocument(true)}} className={classes.button}>Adicionar documento</Button>
                  </>
                )}
                {window.location.pathname == '/sales' && (
                  <>
                    <Button onClick={() => {setOpenModalNewBudget(true)}} className={classes.button}>Novo orçamento</Button>
                  </>
                )}

              </Toolbar>
            </AppBar>

          </Hidden>

          <div className={clsx(classes.content)}>
            <div className={classes.toolbar} />
              {renderRoutes(route.routes, {
                handleClickNewFinancial: openModalNewFinancial,
                handleCloseNewFinancial: () => { setOpenModalNewFinancial(false) },
                handleClickNewInvoice: openModalNewInvoice,
                handleCloseNewInvoice: () => { setOpenModalNewInvoice(false) },
                handleClickNewProject: openModalNewProject,
                handleCloseNewProject: () => { setOpenModalNewProject(false) },
                handleClickNewDocument: openModalNewDocument,
                handleCloseNewDocument: () => { setOpenModalNewDocument(false) },
                handleClickNewBudget: openModalNewBudget,
                handleCloseNewBudget: () => { setOpenModalNewBudget(false) },
                handleClickReport: openReport,
                handleCloseReport: () => { setOpenReport(false) },
                handleValueTabFinancial: (valueTab) => { setValueTabFinancial(valueTab) }
              })}
          </div>

        </Suspense>

      </main>

    </div>
  );
}

export default Layout;