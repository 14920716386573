import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import DashboardLayout from './layouts/Layout';

const routes = [
    {
        path: '/',
        exact: true,
        component: () => <Redirect to="/dashboard" />
    },
    {
        route: '*',
        component: DashboardLayout,
        routes: [
            {
                path: '/projects',
                exact: true,
                component: lazy(() => import('./pages/projects/projects'))
            },
            {
                path: '/financial',
                exact: true,
                component: lazy(() => import('./pages/financial/financial'))
            },
            {
                path: '/dashboard',
                exact: true,
                component: lazy(() => import('./pages/dashboard'))
            },
            {
                path: '/documents',
                exact: true,
                component: lazy(() => import('./pages/documents/documents'))
            },
            {
                path: '/sales',
                exact: true,
                component: lazy(() => import('./pages/sales/sales'))
            }
        ]
    }
];

export default routes;